import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Navigator from '../components/navigator/navigator';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const navigatorItems = [
    { text: 'home', url: '/' },
    { text: 'blog', url: '/blog' },
    {
      text: 'contact me',
      url: 'https://fb.me/ngoctram.nguyen.58118',
      external: true,
    },
  ];

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Ngọc Trâm Store - {frontmatter.title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Sản phẩm tiêu dùng và làm đẹp an toàn." />
        <link rel="canonical" href="https://www.ngoctramstore.com" />
      </Helmet>
      <header className="header">
        <div className="pure-g">
          <div className="pure-u-1">
            <Navigator value={navigatorItems} />
          </div>
        </div>
      </header>
      <div id="content" className="content">
        <div className="pure-g">
          <div className="pure-u-1">
            <h1 className="blog-entry__title">{frontmatter.title}</h1>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1">
            <div className="blog-entry__created">{frontmatter.created}</div>
            <div className="blog-entry__content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>
      <footer id="footer">
        <div className="pure-g">
          <div className="pure-u-1">
            <hr />
            <ul className="info">
              <li className="info__item">Since 2020</li>
              <li className="info__item">
                <a
                  href="https://fb.me/ngoctram.nguyen.58118"
                  className="info__link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  fb.me/ngoctram.nguyen.58118
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        created(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
